import { getValue, toQueryString } from "@/cms-services/helpers";
import axios from "axios";
import { ObjectDataSource } from "./ObjecDatatSource";

export class UserObjectDataSource extends ObjectDataSource {
  private _image: any = null;
  public roles: any = [];
  constructor(id: number) {
    super({
      className: "user",
      config: {},
      id,
    });
  }

  private get imageUrl(): string {
    return `/manage/UserProfile/${this.id}/image/`;
  }

  private get roleUrl(): string {
    return `${this.baseUrl}/roles/`;
  }

  public get image() {
    return this._image?.fileContents
      ? `data:${this._image.contentType};base64,${this._image?.fileContents}`
      : null;
  }

  public async resetPassword(newPassword: string) {
    try {
      const config = { headers: { "Content-Type": "application/json" } };
      await axios.post(this.baseUrl, newPassword, config);
    } catch (error) {
      throw error;
    }
  }

  public async getRoles() {
    try {
      const { data } = await axios.get(this.roleUrl);
      this.roles = data;
    } catch (error) {
      throw error;
    }
  }

  public async addRole(role: any) {
    try {
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } = await axios.post(this.roleUrl, role, config);
      this.roles.push(data);
    } catch (error) {
      throw error;
    }
  }

  public async deleteRole(roleId: number) {
    const roleIndex = this.roles.findIndex((r: any) => r.id === roleId);
    if (roleIndex === -1) throw new Error("Роль не найдена");
    try {
      await axios.delete(`${this.roleUrl}${roleId}`);
      this.roles.splice(roleIndex, 1);
    } catch (error) {
      throw error;
    }
  }

  public async deleteProfileImage() {
    try {
      await axios.delete(this.imageUrl);
      this._image = null;
    } catch (error) {
      throw error;
    }
  }

  public async getProfileImage() {
    try {
      const { data } = await axios.get(this.imageUrl);
      if (!data) return;
      this._image = data;
    } catch (error) {
      throw error;
    }
  }

  public async uploadProfileImage(image: any): Promise<any> {
    try {
      const { data } = await axios.post(this.imageUrl, image.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
        onUploadProgress(e) {
          image.onProgress(e, image.file);
        },
      });
      this._image = data;
    } catch (error) {
      throw error;
    }
  }

  public async updateProfileImage(base64: string): Promise<any> {
    try {
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } = await axios.put(this.imageUrl, base64, config);
      this._image = data;
    } catch (error) {
      throw error;
    }
  }

  public async updateProfileField(filed) {
    try {
      await this.updateField({
        fieldName: filed.name,
        fieldValue: getValue(this.model, filed.name),
      });
    } catch (error) {
      throw error;
    }
  }

  public async confirmEmail(userProfileId: number) {
    try {
      await axios.post(
        `/manage/user/email/confirm/${toQueryString({ userProfileId })}`,
      );
    } catch (error) {
      throw error;
    }
  }

  public async sendConfirmMessage(userProfileId: number) {
    try {
      await axios.post(
        `/manage/user/email/sendconfirmmessage/${toQueryString({
          userProfileId,
        })}`,
      );
    } catch (error) {
      throw error;
    }
  }
}
